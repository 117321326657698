@import url('../../styles/customMediaQueries.css');

.container {
  padding-top: 50px;
  padding: 40px;
  padding-bottom: 47px;
  padding-bottom: 47px;
  background-color: var( --colorWhite);
  font-family: var(--fontFamilyHelmet);
}

.sliderSection {
  max-width: 1248px;
  width: 100%;
  margin: 0 auto;
  /* padding-bottom: 30px; */
  padding-bottom: 48px;
  border-bottom: 1px solid #d9d9d9;
  padding-left: 15px;
  padding-right: 15px;
  @media (--viewportMaxLarge) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.sliderSectionBorder {
  border: none;
  border-top: 1px solid #d9d9d9;
  padding: 69px 40px;
  margin-top: 69px;
  @media (--viewportMaxMedium) {
    padding: 40px 20px;
    margin-top: 40px;
  }
}

.shopListingContainer .sliderSection {
  border: none;
}

.productHeader {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.productHeaderListing {
  justify-content: center;
}

.shopListingContainer .productHeader {
  justify-content: center;
}

.headerBtn {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  width: 32px;
  display: none;
}

.headerBtn img {
  height: 45px;
  margin-top: 16px;
  @media (max-width: 1023px){
    height: 32px;
    margin-top: 0px;
  }
}

.headerBtn:focus,
.headerBtn:hover {
  background-color: transparent;
  box-shadow: none;
}

.shopListingContainer .container .sliderSection .productHeader {
  justify-content: center;
}

.sliderHeading {
  color: var( --colorDarkBlack);
   font-size: var(--fontSizeSubHeading);
  font-style: normal;
  font-weight: 700;
  /* line-height: normal; */
  line-height: 27.28px;
  letter-spacing: 0.44px;
  margin: 0;
}

.provider {
  margin-top: 48px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: row;
  gap: 15px;
  font-family: var(--fontFamilyHelmet);
}

.sliderContainer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 16px;
  width: 100%;
}

.slider {
  width: calc(100% - 11px);
  /* padding-left: 36px; */
  padding-left: 47px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* gap: 22px; */
  gap: 38px;
  justify-content: space-between;
  align-items: center;

  @media (--viewportMaxSmall) {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 0;
  }
  @media (--viewportSmall) and (--viewportMaxLarge) {
    grid-template-columns: repeat(3, 1fr);
    padding-left: 0;
  }
  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr)
  }
}

.slideborder {
  position: relative;
  height: 100%;
}

.mainInfo {
  margin-top: 8px;
  color: var( --colorDarkBlack);
  font-size: var(--fontSizeDescription);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.listingTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 400;
  font-size: var(--fontSizeDescription);
  color: black;
  line-height: normal;
  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--fontSizeSubHeading);
    font-weight: 400;
    line-height: 26px;
    letter-spacing: var(--letterSpacingDescription); 
  }
}

.title {
  font-weight: 400;
  color: var(--colorGrey700);
  font-size: var(--fontSizeDescription);
  line-height: normal;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  color: black;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--fontSizeSubHeading);
    font-weight: 400;
    line-height: 26px;
    letter-spacing: var(--letterSpacingDescription);
  }
}

.price {
  font-weight: 400;
  font-size: var(--fontSizeDescription);
  line-height: normal;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--fontSizeSubHeading);
    font-weight: 400;
    line-height: 26px;
    letter-spacing: var(--letterSpacingDescription);
  }
}

.price div {
  font-weight: 400;
  font-size: var(--fontSizeDescription);
  line-height: normal;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--fontSizeSubHeading);
    font-weight: 400;
    line-height: 26px;
    letter-spacing: var(--letterSpacingDescription);
  }
}

.favouriteBtn {
  width: auto !important;
  height: 30px !important;
  border-radius: 100%;
  background: #fff;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: 0;
  padding: 0 6px;
  cursor: pointer;
}
.favouriteBtn svg {
  width: 18px;
  height:18px;
  margin: 0 auto;
}
.favouriteBtn:hover svg path {
  fill: #973132;
}

.containerListingMain {
  padding: 0;
}

.profileLink {
  position: absolute;
  width: 20vmax;
  top: 40%;
  left: 10%;
  color: black;
  font-size: 2.2vmax;
  text-decoration: underline;
  transform: scale(0);
  transition: transform 0.2s;
  z-index: 5;
  font-weight: 550;
}

.btn {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  width: 45px;
}

.btn:focus,
.btn:hover {
  background-color: transparent;
  box-shadow: none;
}

.sliderImage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.aspectRatioWrapper {
  height: 100%;
}


.aspectRatioWrapper img {
  height: 100%;
  object-fit: cover;
}


@media (max-width: 1023px) {
  .headerBtn {
    display: block;
  }

  .btn {
    display: none;
  }

  .sliderContainer {
    align-items: start;
  }

  .mainInfo {
    font-size: 16px;
  }
}


@media (min-width:1260px){
  .profilePageListing  div{
    height: 377px;
  }
}

@media (--viewportMaxExtraMedium) {
  .container {
    padding: 40px 20px;
  }


  .mainInfo {
     font-size: var(--fontSizeSubText);
  }
}