.breadcrumb {
    display: flex;
    align-items: self-start;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0px;
    width: 100%;
    max-width: 100%;
    margin-inline: auto;
    padding: 0;
    @media screen and (max-width: 1024px) {
        padding: 0 10px;
    }
}
.breadcrumb li:last-child {
    white-space: pre-wrap;
}
.breadcrumbItem {
    list-style: none;
    color: var( --colorDarkBlack);
    white-space: nowrap;
}

.breadcrumbItem a {
    color: black;
    text-decoration: none;
     font-size: var(--fontSizeSubText);
    line-height: 17.36px;
    font-weight: 700;
    padding: 0;
}

.breadcrumbItem a:hover {
    text-decoration: underline;
}

.separator {
    margin: 0 3px;
}